import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select } from "antd";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import instance from "../../../api/api_instance";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import axios from "axios";
import { MdLocationPin } from "react-icons/md";
import ButtonLoader from "../../ButtonLoader";
const { Option } = Select;

function AboutCountries() {
  const [form] = Form.useForm();
  const [countryData, setCountryData] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const[textEditorData , setTextEditorData] = useState(false)

  const geoUrl =
    "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

  const handleChange = (value, event) => {
    var country_data = [];
    event.forEach((element, index) => {
      country_data.push({
        country: element?.value,
        sname: element?.dataName,
        longitude: element?.datalang,
        latitude: element?.datalatitude,
      });
    });

    setSelectedCountries(country_data);
  };

  const onFinish = (values) => {
    setBtnLoading(true)
    const data = {
      count: values.count,
      heading: values.heading,
      description: values.description,
      countries: selectedCountries?.length > 0 ? selectedCountries : [],
    };
    var config = {
      url: `/about/country/insert`,
      method: "POST",
      data: data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            get_data();
            form.resetFields(["history_image"]);
            setBtnLoading(false);
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error({key:"loading",content:"Something went wrong! try again later"});
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({key:"loading",content : error?.response?.data?.message});
          setBtnLoading(false);
        } else {
          message.error({key:"loading",content : error?.message});
          setBtnLoading(false);
        }
      });
  };
  const get_data = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/about/country`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            var prev_countries = response?.data?.data?.countries;
            var temp = [];
            var markers = [];

            if (prev_countries && prev_countries.length > 0) {
              prev_countries.forEach((element,index) => {
                temp.push(element.country);
                markers.push(
                  <Marker key={index + 1} coordinates={[element?.longitude, element.latitude]}>
                    <text
                      textAnchor="middle"
                      fill="#000"
                      className="fs-sm fw-bold"
                      x={8}
                      y={0}
                    >
                      {element?.sname}
                    </text>
                    <MdLocationPin className="text-danger" x={0} y={0} />
                    {/* <circle r={3} fill="#F53" /> */}
                  </Marker>
                );
              });
            }
            setMapMarkers(markers);
            setSelectedCountries(response?.data?.data?.countries);
            form.setFieldsValue({
              count: response?.data?.data?.count,
              heading: response?.data?.data?.heading,
              description: response?.data?.data?.description,
              country: temp,
            });
            setTextEditorData(response?.data?.data?.description)
            message.success({ key: "loading", content: "Data Loaded." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          message.error({
            key: "loading",
            content: error?.response?.data?.message,
          });
        } else {
          message.error({ key: "loading", content: error?.message });
        }
      });
  };

  const get_country_data = () => {
    axios.get(`/assets/country.json`).then((res) => {
      var countryData = [];
      res.data.forEach((element, index) => {
        countryData.push(
          <Option
            key={index + 1}
            value={element.country}
            dataName={element?.alpha3 ?? element?.alpha2 ?? element?.country}
            datalang={element.longitude}
            datalatitude={element.latitude}
          >
            <div className="demo-option-label-item">
              {element.country}
              <span role="img" aria-label={element.country}>
                ({element.alpha2})
              </span>
            </div>
          </Option>
        );
      });
      setCountryData(countryData);
    });
  };

  useEffect(() => {
    get_data();
    get_country_data();
  }, []);
 
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card border-0">
          <div className="card-header border-0">
            <p className="mb-0 fw-500 fs-6">About Countries</p>
          </div>
          <div className="card-body">
            <Form
              name="product_form"
              className="login-form"
              form={form}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    name="count"
                    label="Count"
                    rules={[
                      {
                        required: true,
                        message: "Please input count!",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Enter Count" />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item
                    name="heading"
                    label="Heading"
                    rules={[
                      {
                        required: true,
                        message: "Please input heading!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Heading" />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input description!",
                      },
                    ]}
                  >
                   <SunEditor
                      setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"200px"
                      }}
                    />
                    {/* <ReactQuill theme="snow" modules={modules} /> */}
                    {/* <TextArea rows="3" placeholder="Enter Description" /> */}
                  </Form.Item>
                </div>

                <div className="col-md-6 country-dropdown " id="country-select-menu">
                  <Form.Item
                    name="country"
                    label="Countries"
                    rules={[
                      {
                        required: true,
                        message: "Please select countries!",
                      },
                    ]}
                  >
                  
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="select country"
                      // defaultValue={["india"]}
                      onChange={(value, event) => handleChange(value, event)}
                      className="country-select"
                      getPopupContainer={() => document.getElementById('country-select-menu')}
                      optionLabelProp="label"
                    >
                      {countryData}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-2">
                  <Form.Item className="text-end">
                    <Button
                      htmlType="submit"
                      className="form-btn-style"
                      disabled={btnLoading ? true : false}
                    >
                      {btnLoading ? (
                        <span className="me-2">
                          <ButtonLoader />
                        </span>
                      ) : (
                        ""
                      )}
                      Save
                    </Button>
                    {/* <Button htmlType="submit" className="form-btn-style">
                      Save
                    </Button> */}
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="col-md-12 my-3 ">
        <div className="card border-0">
          <div className="card-body p-5">
            <ComposableMap width={1000}>
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      style={{
                        default: {
                          fill: "#AED6F1",
                        },
                        hover: {
                          fill: "#5D6D7E",
                        },
                        pressed: {
                          fill: "#E42",
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
              {mapMarkers}
            </ComposableMap>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutCountries;

import React from "react";
import ContactMobile from "../../component/admin_component/contact_us/ContactMobile";
import ContactEmail from "../../component/admin_component/contact_us/ContactEmail";
import SocialMedia from "../../component/admin_component/contact_us/SocialMedia";
import Address from "../../component/admin_component/contact_us/Address";
import ContactHeading from "../../component/admin_component/contact_us/ContactHeading";
export default function ContactUs() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Contact Information
                <span className="primary-font">(On website)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <ContactHeading/>
        {/* CONTACT NUMBER */}
	      <ContactMobile/>
        {/* EMAIL */}
       <ContactEmail/>
        {/* SOCIAL LINKS */}
       <SocialMedia/>
      </div>
      <div className="row my-3">
	{/* CONTACT ADDRESS */}
        <Address/>
      </div>
    </div>
  );
}

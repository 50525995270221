import React, { useEffect, useState } from "react";
import {
  UploadOutlined,
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Image,
  message,
  Space,
  Modal,
} from "antd";
import instance from "../../../api/api_instance";
import { GiCancel } from "react-icons/gi";
import ButtonLoader from "../../ButtonLoader";
import ImageSeoModal from "../../ImageSeoModal";

const { Dragger } = Upload;

const { TextArea } = Input;

export default function WebsiteHeader() {
  const [form] = Form.useForm();
  const [emailData, setEmailData] = useState([]);
  const [mobileData, setMobileData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [headerForm, setHeaderForm] = useState([]);
  const [prevHeaderBanner, setPrevHeaderBanner] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const get_email_mobile = () => {
    var config = {
      url: `/home-contacts`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            var emailData = [];
            var mobileData = [];
            var addressData = [];
            if (
              response?.data?.data?.email &&
              response?.data?.data?.email?.length > 0
            ) {
              response?.data?.data?.email?.forEach((element, index) => {
                emailData.push({
                  value: element,
                  label: element,
                });
              });
              setEmailData(emailData ?? []);
            }
            if (
              response?.data?.data?.mobile &&
              response?.data?.data?.mobile?.length > 0
            ) {
              response?.data?.data?.mobile?.forEach((element, index) => {
                mobileData.push({
                  value: element,
                  label: element,
                });
              });
              setMobileData(mobileData ?? []);
            }
            if (
              response?.data?.data?.address &&
              response?.data?.data?.address?.length > 0
            ) {
              response?.data?.data?.address?.forEach((element, index) => {
                addressData.push({
                  value: index,
                  label: element.location,
                });
              });
              setAddressData(addressData ?? []);
            }
          } else {
            // message.error(response.data.message);
          }
        } else {
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  const get_home_form_data = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/home-header`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setHeaderForm(response.data.data);
            setPrevHeaderBanner(response?.data?.data?.header_banner ?? []);
            message.success({ key: "loading", content: "Data Loaded" });
            form.setFieldsValue({
              email: response?.data?.data?.email,
              mobile: response?.data?.data?.mobile,
              // header_heading: response?.data?.data?.header_heading,
              // header_link: response?.data?.data?.header_link,
              section_heading: response?.data?.data?.section_heading,
              // section_link: response?.data?.data?.section_link,
              footer_content: response?.data?.data?.footer_content,
              quick_links: response?.data?.data?.quick_links,
              address: response?.data?.data?.footer_address,
            });

            form.resetFields([
              "logo",
              "header_banner",
              "section_banner",
              "footer_bg",
            ]);
          } else {
            message.error({ key: "loading", content: response?.data?.message });
            // message.error(response.data.message);
          }
        } else {
          message.error({ key: "loading", content: response?.message });
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };
  // DELETE BANNER IMAGES FROM BANNER GALLARY
  const deleteImage = (event, id) => {
    event.preventDefault();
    setPrevHeaderBanner((current) => current.filter((_, i) => i !== id));
  };
  useEffect(() => {
    get_email_mobile();
    get_home_form_data();
  }, []);

  const onFinish = (values) => {
    setBtnLoading(true);

    const headerIdArray = prevHeaderBanner.map((obj) => obj._id);

    message.loading({ key: "loading", content: "please wait..." });
    let formdata = new FormData();
    formdata.append("email", values?.email);
    formdata.append("mobile", values?.mobile);
    formdata.append("footer_address", values?.address);
    formdata.append(
      "prevBanner",
      prevHeaderBanner ? JSON.stringify(headerIdArray) : []
    );
    formdata.append(
      "section_heading",
      values?.section_heading ? values?.section_heading?.trim() : ""
    );
    formdata.append(
      "footer_content",
      values?.footer_content ? values?.footer_content?.trim() : ""
    );
    formdata.append(
      "quick_links",
      values?.quick_links ? JSON.stringify(values?.quick_links) : ""
    );
    if (values?.logo) {
      formdata.append("logo", values?.logo?.fileList[0]?.originFileObj);
    }
    if (
      values?.heading_banner?.fileList &&
      values?.heading_banner?.fileList?.length > 0
    ) {
      values?.heading_banner?.fileList?.forEach((element) => {
        formdata.append("header_banner", element?.originFileObj);
      });
    }
    if (values?.section_banner) {
      formdata.append(
        "section_banner",
        values?.section_banner?.fileList[0]?.originFileObj
      );
    }
    if (values?.footer_bg) {
      formdata.append(
        "footer_bg",
        values?.footer_bg?.fileList[0]?.originFileObj
      );
    }
    var config = {
      url: `/insert-home-header`,
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ key: "loading", content: response.data.message });
            form.resetFields(["heading_banner", "section_banner", "footer_bg"]);
            get_home_form_data();
            setBtnLoading(false);
          } else {
            message.error({ key: "loading", content: response.data.message });
            setBtnLoading(false);
          }
        } else {
          message.error({
            key: "loading",
            content: "Something went wrong! try again later",
          });
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({
            key: "loading",
            content: error?.response?.data?.message,
          });
        } else {
          message.error({ key: "loading", content: error?.message });
        }
        setBtnLoading(false);
      });
  };

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">Main Contents</p>
      </div>
      <div className="card-body">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          requiredMark={false}
        >
          {/* HEADING */}
          <div className="row">
            <div className="col-md-12">
              <p className="fw-500 primary-font fs-5 ">Top nav</p>
            </div>
          </div>
          <div className="row my-2 border-bottom">
            <div className="col-md-6 col-lg-2">
              <Form.Item
                label="Logo"
                name="logo"
                rules={[
                  {
                    required: headerForm?.logo?._id ? false : true,
                    message: "Please upload logo!",
                  },
                ]}
              >
                <Upload
                  multiple={false}
                  accept="image/*"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Logo
                  </Button>
                </Upload>
              </Form.Item>
            </div>
            {headerForm?.logo ? (
              <div className="col-md-6 col-lg-3 border  my-2">
                <Image
                  src={`${
                    process.env.REACT_APP_API_URL + "/" + headerForm?.logo?.path
                  }`}
                  alt=""
                  className="img-fluid"
                />
                <ImageSeoModal imageId={headerForm?.logo?._id} />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-6 col-lg-3">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please select your email!",
                  },
                ]}
              >
                <Select placeholder="Select Email" options={emailData} />
              </Form.Item>
            </div>
            <div className="col-md-6 col-lg-4">
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Please select your mobile no!",
                  },
                ]}
              >
                <Select placeholder="Select mobile no" options={mobileData} />
              </Form.Item>
            </div>
          </div>
          {/* HEADING */}
          <div className="row my-2">
            <div className="col-md-12">
              <p className="fw-500 primary-font fs-5">Header</p>
            </div>
          </div>
          <div className="row my-2  pb-2 border-bottom">
            <div className="col-md-6 ">
              <Form.Item
                label="banner"
                name="heading_banner"
                rules={[
                  {
                    required: headerForm?.header_banner ? false : true,
                    message: "Please upload header banner!",
                  },
                ]}
              >
                <Dragger
                  multiple={true}
                  accept="image/*"
                  beforeUpload={() => false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div className="border w-100 h-auto py-5 d-flex justify-content-center align-items-center ">
                <button
                  className="btn btn-sm btn-outline-secondary me-2 fw-500 shadow-none"
                  onClick={(e) => showModal(e)}
                >
                  Banner Gallary
                </button>
              </div>
            </div>
            {/* <div className="col-md-4">
              <Form.Item
                label="Heading"
                name="header_heading"
                rules={[
                  { required: true, message: "Please input your heading!" },
                ]}
              >
                <Input placeholder="Enter Heading" />
              </Form.Item>
              <Form.Item
                name="header_link"
                label="Link"
                rules={[
                  {
                    required: true,
                    type: "url",
                    message: "Please input your banner link!",
                  },
                ]}
              >
                <Input type="url" placeholder="Enter banner link" />
              </Form.Item>
            </div> */}
          </div>
          {/* SECTION HEADING */}
          <div className="row my-2 ">
            <div className="col-md-12">
              <p className="fw-500 primary-font fs-5">Get in touch</p>
            </div>
          </div>
          {/* SECTION FORM */}
          <div className="row my-2 border-bottom">
            <div className="col-md-6 col-lg-4">
              <Form.Item
                label="banner"
                name="section_banner"
                rules={[
                  {
                    required: headerForm?.section_banner?._id ? false : true,
                    message: "Please upload section banner!",
                  },
                ]}
              >
                <Dragger
                  multiple={false}
                  accept="image/*"
                  beforeUpload={() => false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </div>
            {headerForm?.section_banner ? (
              <div className="col-md-6 col-lg-4 d-flex flex-column justify-content-center align-items-start border mb-1">
                <Image
                  src={`${
                    process.env.REACT_APP_API_URL +
                    "/" +
                    headerForm?.section_banner?.path
                  }`}
                  alt=""
                  className="img-fluid form-banner-img"
                />
                <ImageSeoModal imageId={headerForm?.section_banner?._id} />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-6 col-lg-4">
              <Form.Item
                label="Heading"
                name="section_heading"
                rules={[
                  { required: true, message: "Please input your heading!" },
                ]}
              >
                <TextArea rows={5} placeholder="Enter Heading" />
              </Form.Item>
            </div>
          </div>
          {/* FOOTER HEADING */}
          <div className="row my-2 ">
            <div className="col-md-12">
              <p className="fw-500 primary-font fs-5">Footer</p>
            </div>
          </div>
          {/* FOOTER FORM */}
          <div className="row my-2 border-bottom">
            <div className="col-md-6 col-lg-4">
              <Form.Item
                label="Footer Background"
                name="footer_bg"
                rules={[
                  {
                    required: headerForm?.footer_bg?._id ? false : true,
                    message: "Please upload footer background!",
                  },
                ]}
              >
                <Dragger
                  multiple={false}
                  accept="image/*"
                  beforeUpload={() => false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </div>
            {headerForm?.footer_bg && headerForm?.footer_bg?._id ? (
              <div className="col-md-6 col-lg-4 d-flex flex-column justify-content-center align-items-start border mb-1">
               
                  <Image
                    src={`${
                      process.env.REACT_APP_API_URL +
                      "/" +
                      headerForm?.footer_bg?.path
                    }`}
                    alt=""
                    className="img-fluid form-banner-img"
                  />
             
                <ImageSeoModal imageId={headerForm?.footer_bg?._id} />
                
              </div>
              
            ) : (
              ""
            )}
            <div className="col-md-6 col-lg-4 mt-3">
              <Form.Item
                label="Footer Content"
                name="footer_content"
                rules={[
                  { required: true, message: "Please input footer content!" },
                ]}
              >
                <TextArea rows="5" placeholder="Enter Footer Content" />
              </Form.Item>
            </div>
            <div className="col-md-6 col-lg-4">
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please select your address!",
                  },
                ]}
              >
                <Select placeholder="Select address" options={addressData} />
              </Form.Item>
            </div>
          </div>

          {/* QUICK LINKS */}

          {/* FOOTER HEADING */}
          <div className="row my-2">
            <div className="col-md-12">
              <p className="fw-500 primary-font fs-5">Add quick links</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Form.List name="quick_links">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        // direction="vertical"

                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "link_title"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Link Title",
                            },
                          ]}
                        >
                          <Input placeholder="Enter link title" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "link_url"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing url!",
                            },
                            {
                              type: "url",
                              message: "Enter valid url!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter url" />
                        </Form.Item>

                        <div className="text-start">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        className="d-inline-block"
                        // block
                        icon={<PlusOutlined />}
                      >
                        Add New
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>

          <Form.Item className="text-end">
            <Button
              htmlType="submit"
              className="form-btn-style"
              disabled={btnLoading ? true : false}
            >
              {btnLoading ? (
                <span className="me-2 ">
                  <ButtonLoader />
                </span>
              ) : (
                ""
              )}
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        title="Banner Gallary"
        visible={isModalOpen}
        // onOk={handleOk}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        width={"50%"}
      >
        <div className="conatainer">
          <div className="row">
            {prevHeaderBanner && prevHeaderBanner?.length > 0
              ? prevHeaderBanner.map((element, index) => {
                  return (
                    <div key={index + 1} className="col-md-4 text-end">
                      <div className="d-flex justify-content-end align-items-center">
                        <ImageSeoModal imageId={element?._id} />
                        <button
                          className=" border-0  btn btn-sm btn-light  fw-500"
                          onClick={(event) => deleteImage(event, index)}
                        >
                          <GiCancel className="fs-4 font-red" />
                        </button>
                      </div>

                      {element ? (
                        <Image
                          key={index + 1}
                          src={`${process.env.REACT_APP_API_URL}/${element?.path}`}
                          className="img-fluid"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </Modal>
    </div>
  );
}

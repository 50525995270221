import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AboutContent from "../../../component/admin_component/about_component/AboutContent";
import AboutCountries from "../../../component/admin_component/about_component/AboutCountries";
import AboutHistory from "../../../component/admin_component/about_component/AboutHistory";
import AboutProducts from "../../../component/admin_component/about_component/AboutProducts";
import AboutWhyUs from "../../../component/admin_component/about_component/AboutWhyUs";


function AboutUs() {

  const [activeTab, setActiveTab] = useState("header");

  const navigate = useNavigate();

  const { search } = useLocation();

  const urlparams = new URLSearchParams(search);

  const currentTab = urlparams.get("tab");

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    } else {
      setActiveTab("content");
    }
  }, [currentTab]);

  const renderAboutComponent = () => {
    switch (activeTab) {
      case "content":
        return <AboutContent/>
      case "country":
        return <AboutCountries/>;
      case "products":
        return <AboutProducts/>;
      case "whyus":
        return <AboutWhyUs/>;
      case "history":
        return <AboutHistory/>;
      default:
        <AboutContent/>;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                About page
                <span className="primary-font">(On website)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12 ">
          <ul className="tab-list px-2 bg-white py-3 rounded">
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "content" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/about-us?tab=content`)}
              >
                About Content
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "country" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/about-us?tab=country`)}
              >
                Countries
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "products" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/about-us?tab=products`)}
              >
                About Products
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "whyus" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/about-us?tab=whyus`)}
              >
                Why Choose us
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "history" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/about-us?tab=history`)}
              >
                History
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
              {renderAboutComponent()}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

export const ActionTypes ={
    // states for admin panel layout
  
    SET_SIDEBAR_STATUS: "SET_SIDEBAR_STATUS",

     
    //Admin Login constants

    ADMIN_LOGIN_SUCCESS : "ADMIN_LOGIN_SUCCESS",
    ADMIN_LOGIN_FAILURE : "ADMIN_LOGIN_FAILURE",

    //Admin Logout constant
    ADMIN_LOGOUT:"ADMIN_LOGOUT",


}
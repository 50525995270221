import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Button, Form, Input, Modal, message } from "antd";
import { AiOutlineUser, AiOutlinePhone } from "react-icons/ai";
import instance from "../../../api/api_instance";

function ContactMobile() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileData, setMobileData] = useState([]);

  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteMobile = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/delete-mobile/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              setIsModalOpen(false);
              get_mobile();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error.message);
          }
        });
    }
  };

  const onFinish = (values) => {
    var config = {
      url: `/insert-mobile`,
      method: "POST",
      data: { mobile: values?.mobile?.trim() },
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            message.success(response.data.message);
            setIsModalOpen(false);
            get_mobile();
            form.resetFields();
            // setEmailData(response?.data?.data);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  const get_mobile = () => {
    message.loading({ key: "loading", content: "loading" });
    var config = {
      url: `/fetch-mobile`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setMobileData(response?.data?.data);
            message.success({ key: "loading", content: "Data Loaded" });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  useEffect(() => {
    get_mobile();
  }, []);
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="card border-0">
        <div className="card-header border-bottom d-flex justify-content-between py-3">
          <p className="mb-0 fw-500 fs-6">Contact Number</p>
          <button
            className="mb-0 primary-font border-0 bg-transparent"
            onClick={showModal}
          >
            <RiAddCircleLine className="fs-5 primary-font" />
          </button>
        </div>
        <div className="card-body">
          <table className="table mb-0">
            <tbody>
          {mobileData && mobileData?.length > 0
                ? mobileData.map((elment, index) => {
                    return (
                      <tr key={index + 1}>
                        <td>
                          <p>{elment}</p>
                        </td>
                        <td className="text-end">
                          <button
                            className=" fs-5 d-inline-flex btn btn-sm primary-font text-end"
                            onClick={() => deleteMobile(index)}
                          >
                            <AiOutlineDelete />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                : null}</tbody>
            {/* <tr>
              <td>
                <p >9554224578</p>
              </td>
              <td className="d-flex justify-content-end">
                <button className=" fs-5 d-inline-flex btn btn-sm primary-font text-end">
                  <AiOutlineDelete />
                </button>
              </td>
            </tr> */}
          </table>
        </div>
      </div>
      <Modal
        title="Add Contact number"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="mobile"
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Input valid mobile number!",
              },
              {
                required: true,
                message: "Please input your mobile number!",
              },
            ]}
          >
            <Input
              prefix={<AiOutlinePhone className="site-form-item-icon" />}
              type="number"
              placeholder="Enter Mobile number"
            />
          </Form.Item>

          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ContactMobile;

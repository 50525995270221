import React, { useState } from "react";

import { Form, Input, Button, Select, message } from "antd";
import instance from "../../../api/api_instance";
import ButtonLoader from "../../ButtonLoader";
const { TextArea } = Input;

export default function AddSeoForm({ getData }) {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const onFinish = (values) => {
    setBtnLoading(true);
    const seo_data = {
      head_title: values.head_title,
      meta_title: values.meta_title,
      meta_description: values.meta_description,
      page_name: values.page_name,
      meta_keyword: values.meta_keyword,
    };
    var config = {
      url: `/seo/create`,
      method: "POST",
      data: seo_data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({
              content: response.data.message,
              key: "loading",
            });
            form.resetFields();
            getData();
            setBtnLoading(false);
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error("Something went wrong! try again later");
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
          setBtnLoading(false);
        } else {
          message.error(error?.message);
          setBtnLoading(false);
        }
      });
  };
  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6 secondary-font">Add New SEO</p>
      </div>
      <div className="card-body">
        <Form
          name="product_form"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                name="head_title"
                label="Head Title"
                rules={[
                  {
                    required: true,
                    message: "Please input head title!",
                  },
                ]}
              >
                <Input placeholder="Enter Head Title" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="meta_title"
                label="Meta Title"
                rules={[
                  {
                    required: true,
                    message: "Please input meta title!",
                  },
                ]}
              >
                <Input placeholder="Enter Meta Title" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="meta_description"
                label="Meta Description"
                rules={[
                  {
                    required: true,
                    message: "Please input meta description!",
                  },
                ]}
              >
                <TextArea rows={5} placeholder="Enter Meta Description" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="meta_keyword"
                label="Meta Keyword"
                rules={[
                  {
                    required: true,
                    message: "Please input meta keyword!",
                  },
                ]}
              >
                <Input placeholder="Enter Meta keyword" />
              </Form.Item>
              <Form.Item
                name="page_name"
                label="Page name"
                rules={[
                  {
                    required: true,
                    message: "Please Select page!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Page Name"
                  options={[
                    {
                      label: "Home Page",
                      value: "home",
                    },
                    {
                      label: "About us Page",
                      value: "about",
                    },
                    {
                      label: "Contact us Page",
                      value: "contact",
                    },
                    {
                      label: "Blog Page",
                      value: "blog",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item className="text-end">
                <Button
                  htmlType="submit"
                  className="form-btn-style"
                  disabled={btnLoading ? true : false}
                >
                  {btnLoading ? (
                    <span className="me-2">
                      <ButtonLoader />
                    </span>
                  ) : (
                    ""
                  )}
                  Add SEO
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

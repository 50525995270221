import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Modal } from "antd";
import Footerweb from "../../component/website_component/Footerweb";
import Navbarweb from "../../component/website_component/Navbarweb";
import EnquiryForm from "./EnquiryForm";
import { AiOutlineClose } from "react-icons/ai";

function WebsiteLayout() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openBottomPopup, setOpenBottomPopup] = useState(false);
  const [contactNo, setContactNo] = useState(null);
  // useEffect(() => {
  //   var enquiryDate = localStorage.getItem("enquiryDate")
  //   var today = new Date().getDate()
  //   if(!enquiryDate || new Date(enquiryDate).getDate() !==  today){
  // setTimeout(() => {
  //   localStorage.setItem("enquiryDate",new Date())
  //   setIsModalOpen(true);
  // }, 30000);
  //   }
  // }, []);
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    var enquiryDate = localStorage.getItem("enquiryDate");
    var today = new Date().getDate();
    if (!enquiryDate || new Date(enquiryDate).getDate() !== today) {
      if (width > 100 && width < 768) {
        setTimeout(() => {
          localStorage.setItem("enquiryDate", new Date());
          setIsModalOpen(true);
        }, 60000);
      } else {
        setTimeout(() => {
          localStorage.setItem("enquiryDate", new Date());
          setOpenBottomPopup(true);
        }, 60000);
      }
    }
  }, [width]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="layout-wrapper">
      <Navbarweb setContactNo={setContactNo} />
      <div
        className={`enquiryPopup ${openBottomPopup ? "open-equiry" : ""} ${
          width < 768 ? "d-none" : "d-block"
        }`}
      >
        <div className="top-close-btn">
          <button className="" onClick={() => setOpenBottomPopup(false)}>
            <AiOutlineClose />
          </button>
        </div>
        <div className="row">
          <div className="col-md-7">
            <p className=" fw-500 font-24 mb-2 dark-font">Quick Enquiry</p>
            <p className="text-justify font-18 dark-79">
              Got a question ? Contact us quickly and easily using the following
              form and we will get back to you ASAP.
            </p>
          </div>
          <div className="col-md-5">
            <img
              src="./images/enquiry.png"
              alt="enquiry-img"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-sm send-btn"
            onClick={() => {
              setIsModalOpen(true);
              setOpenBottomPopup(false);
            }}
          >
            Send enquiry
          </button>
          <button
            className="btn ms-2 btn-sm fw-500 close-btn"
            onClick={() => setOpenBottomPopup(false)}
          >
            No Thanks
          </button>
        </div>
      </div>

      <div className="content-wrapper">
        <Modal
          title={
            <p className="font-22 mb-0">
              Quick Enquiry{" "}
              <span className="font-16 dark-79">({contactNo})</span>
            </p>
          }
          visible={isModalOpen}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          width={600}
        >
          <div className="enquiry-form-wrapper">
            <p className="font-20 dark-79">
              Let us know what you're interested in, and we'll send you the
              details you need.
            </p>
            <EnquiryForm />
          </div>
        </Modal>
        <Outlet />
      </div>
      <Footerweb />
    </div>
  );
}

export default WebsiteLayout;

import React, { useEffect, useState } from "react";
import { Button, Table, message } from "antd";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import instance from "../../../api/api_instance";
import { useNavigate } from "react-router-dom";

function AllPages() {
  const [pageData, setPageData] = useState([]);
  const navigate = useNavigate();
  const columns = [
    {
      title: "Page Title",
      dataIndex: "page_title",
      key: "page_title",
    },
    {
      title: "Page Slug",
      dataIndex: "page_slug",
      key: "page_slug",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "200",
      render: (text, record) => (
        <div key={record._id} className="d-flex">
        {
          record?.page_slug ? 
          <Button
            className="btn btn-outline-secondary btn-sm m-1 fw-500"
            onClick={() => {
              navigate("/"+record?.page_slug)
              // window.location.href = "http://localhost:3008";
              // const externalLink = document.createElement('a');
              // externalLink.href = record?.page_url; // Replace with the URL of the external website
              // externalLink.target = '_blank';
              // // Trigger a click event on the dynamically created link
              // externalLink.click();
            }}
          >
            <AiOutlineEye />
          </Button> : ""
        }
        
          <Button
            className="btn btn-outline-info btn-sm m-1 fw-500"
            onClick={() => navigate(`/admin/page/edit/${record._id}`)}
          >
            <FiEdit />
          </Button>

          <Button className="btn btn-outline-primary btn-sm m-1 fw-500" onClick={()=>{deletePage(record?._id)}}>
            <AiOutlineDelete className="font-red" />
          </Button>
        </div>
      ),
    },
  ];

  const fetchData = () => {
    message.loading({ key: "loading", content: "Loading..." });
    var config = {
      url: `/pages`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setPageData(response?.data?.data ?? []);
            message.success({ key: "loading", content: "Pages data fetched successfully." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
      });
  };

  const deletePage = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/page/delete/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              fetchData();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };


  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Dynamic Pages
                {/* <span className="primary-font ms-2">(On website)</span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="card border-0 p-0 table-responsive">
            <Table dataSource={pageData} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllPages;

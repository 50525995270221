import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import instance from "../../../api/api_instance";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ButtonLoader from "../../ButtonLoader";
const {TextArea} = Input;

function AboutWhyUs() {
  const [form] = Form.useForm();
  const[btnLoading , setBtnLoading] = useState(false)
  const[textEditorData ,setTextEditorData] = useState("")
  const onFinish = (values) => {
    setBtnLoading(true)
    const data  ={
      heading : values?.heading,
      description : values?.description,
      card1 : values?.card1,
      card2 : values?.card2,
      card3 : values?.card3
    }
    var config = {
      url: `/about/whyus/insert`,
      method: "POST",
      data: data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 &&
          response?.status < 300
        ) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
            get_data()
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error({key:"loading",content : "Something went wrong! try again later"});
          setBtnLoading(false)
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({key:"loading",content : error?.response?.data?.message});
          setBtnLoading(false)
        } else {
          message.error({key:"loading",content: error?.message});
          setBtnLoading(false)
        }
      });
  };
  const get_data = () => {
    message.loading({key:"loading",content:"Please wait..."})
    var config = {
      url: `/about/whyus`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            form.setFieldsValue({
              heading: response?.data?.data?.heading,
              description: response?.data?.data?.description,
              card1: response?.data?.data?.card1,
              card2: response?.data?.data?.card2,
              card3: response?.data?.data?.card3
            });
            setTextEditorData(response?.data?.data?.description)
            message.success({key:"loading",content:"Data Loaded."})
          } else {
            message.error({key:"loading",content:response.data.message})
          }
        } else {
          message.error({key:"loading",content:response.message})
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({key:"loading",content:error.response.data.message});
        } else {
          message.error({key:"loading",content:error.message});
        }
      });
  };

  useEffect(() => {
    get_data();
  }, []);

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">Why choose us</p>
      </div>
      <div className="card-body">
        <Form
          name="product_form"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <div className="row border-bottom">
            
                <div className="col-md-12">
                  <Form.Item
                    name="heading"
                    label="Heading"
                    rules={[
                      {
                        required: true,
                        message: "Please input heading!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Heading" />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input description!",
                      },
                    ]}
                  >
                    <SunEditor
                      setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"150px"
                      }}
                    />
                  </Form.Item>
                </div>
             
          </div>

          <div className="row my-2">
            <div className="col-md-12">
              <p className="fw-500 primary-font">Card 1</p>
            </div>
          </div>
          <div className="row border-bottom">
            <div className="col-md-6">
              <Form.Item
                label = "Card Number"
                name={["card1", "card_no"]}
                rules={[
                  {
                    required: true,
                    message: "card no is required",
                  },
                ]}
              >
                <Input placeholder="Enter Card Number"/>
              </Form.Item>
              <Form.Item
                label = "Card Name"
                name={["card1", "card_title"]}
                rules={[
                  {
                    required: true,
                    message: "card title is required",
                  },
                ]}
              >
                <Input placeholder="Enter card title"/>
              </Form.Item>
            </div>
            {/* <div className="col-md-4">
            
            </div> */}
            <div className="col-md-6">
              <Form.Item
                label = "Card Content"
                name={["card1", "card_content"]}
                rules={[
                  {
                    required: true,
                    message: "card content is required",
                  },
                ]}
              >
                <TextArea rows="5" placeholder="Enter card content"/>
              </Form.Item>
            </div>
          </div>


          {/* CARD 2 */}

          <div className="row my-2">
            <div className="col-md-12">
              <p className="fw-500 primary-font">Card 2</p>
            </div>
          </div>
          <div className="row border-bottom">
            <div className="col-md-6">
              <Form.Item
                label = "Card Number"
                name={["card2", "card_no"]}
                rules={[
                  {
                    required: true,
                    message: "card no is required",
                  },
                ]}
              >
                <Input placeholder="Enter card no"/>
              </Form.Item>
              <Form.Item
                label = "Card Title"
                name={["card2", "card_title"]}
                rules={[
                  {
                    required: true,
                    message: "card title is required",
                  },
                ]}
              >
                <Input placeholder="Enter card title"/>
              </Form.Item>
            </div>
        
            <div className="col-md-6">
              <Form.Item
                label = "Card Content"
                name={["card2", "card_content"]}
                rules={[
                  {
                    required: true,
                    message: "card content is required",
                  },
                ]}
              >
                <TextArea rows="5" placeholder="Enter card content"/>
              </Form.Item>
            </div>
          </div>

          {/* CARD 3 */}

          <div className="row my-2">
            <div className="col-md-12">
              <p className="fw-500 primary-font">Card 3</p>
            </div>
          </div>
          <div className="row border-bottom">
            <div className="col-md-6">
              <Form.Item
                label = "Card Number"
                name={["card3", "card_no"]}
                rules={[
                  {
                    required: true,
                    message: "card no is required",
                  },
                ]}
              >
                <Input placeholder="Enter card no"/>
              </Form.Item>
              <Form.Item
                label = "Card Title"
                name={["card3", "card_title"]}
                rules={[
                  {
                    required: true,
                    message: "card title is required",
                  },
                ]}
              >
                <Input placeholder="Enter card title"/>
              </Form.Item>
            </div>
            {/* <div className="col-md-4">
            
            </div> */}
            <div className="col-md-6">
              <Form.Item
                label = "Card Content"
                name={["card3", "card_content"]}
                rules={[
                  {
                    required: true,
                    message: "card content is required",
                  },
                ]}
              >
                <TextArea rows="5" placeholder="Enter card content" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-2">
              <Form.Item className="text-end">
              <Button
                htmlType="submit"
                className="form-btn-style"
                disabled={btnLoading ? true : false}
              >
                {btnLoading ? (
                  <span className="me-2">
                    <ButtonLoader />
                  </span>
                ) : (
                  ""
                )}
                Save
              </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AboutWhyUs;


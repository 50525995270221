import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from './../../redux/constants/ActionTypes';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from "../../component/LoadingSpinner";
import ButtonLoader from "../../component/ButtonLoader";


function ChangePassword() {
  const [form] = Form.useForm();

  const adminData = useSelector((state)=> state.auth.admin)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  var isLoggedIn = localStorage.getItem("loggedIn")

  var data = null
  if (isLoggedIn) {
    data = JSON.parse(localStorage.getItem("adminInfo"))
  }
  else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

  const onFinish = (values) => {
    if (values.new_password === values.confirm_password) {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_API_URL}/adminapi/change-password`, {
        current_password: values.current_password,
        new_password: values.new_password,
        id: adminData._id
      }).then((response) => {

        if (response?.statusText === "OK" && response?.status >= 200 && response?.status < 300) {
          if (response?.data?.status) {
            message.success({ content: response?.data?.message, key: "data" })
            setLoading(false)
            form.resetFields()
          }
          else {
            message.error({ content: response?.data?.message, key: "data" })
            setLoading(false)
          }
        } else {
          message.error("Something went wrong! try again later.")
          setLoading(false)
        }
       
      }).catch((error) => {
        console.log("here")
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message)
          setLoading(false)
        } else {
          message.error(error?.message)
          setLoading(false)
        }
      });;
    }
    else {
      message.error("Confirm password doesn't match");
    }

    //   setLoading(true)



  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Change Password</p>
            </div>
            <div>
              <Form
                form={form}
                name="form"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="col-md-12">
                  <Form.Item
                    name="current_password"
                    className="form-item"
                    label="Current Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                        whitespace: true
                      }
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>

                <div className="col-md-12">
                  <Form.Item
                    name="new_password"
                    className="form-item"
                    label="New Password"
                    rules={[
                      {
                        pattern:
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        message:
                          "password must contain minimum eight characters, at least one letter, one number and one special character."
                      },
                      {
                        required: true,
                        message: "Please input your password!",
                        whitespace: true
                      }
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    name="confirm_password"
                    className="form-item"
                    label="Confirm Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                        whitespace: true
                      }
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>

                <div className="col-md-12">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="form-btn border-0"
                      htmlType="submit"
                      disabled={loading}
                      to="/"
                    >
                      {loading ?  <ButtonLoader/> : "Change Password"}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;

import React, { useEffect, useState } from "react";
import { Form, Button, message } from "antd";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

import instance from "../../../api/api_instance";
import ButtonLoader from "../../ButtonLoader";

function AboutContent() {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [textEditorData , setTextEditorData] = useState("")
  const get_data = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/about/content`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            form.setFieldsValue({
              description: response?.data?.data?.about_content,
            });
            setTextEditorData(response?.data?.data?.about_content)
            message.success({ key: "loading", content: "Data Loaded." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
      });
  };

  const onFinish = (values) => {
    setBtnLoading(true);
    const data = {
      content: values.description,
    };

    var config = {
      url: `/about/content/insert`,
      method: "POST",
      data: data,
    };

    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
            get_data();
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false);
          }
        } else {
          message.error("Something went wrong! try again later");
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Server can't be reached!
        message.error(error?.message);
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    get_data();
  }, []);

  return (
    <div className="card border-0">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">About content</p>
      </div>
      <div className="card-body">
        <Form
          name="content_form"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <div className="col-md-12">
            <Form.Item
              name="description"
              rules={[
                { required: true, message: "Please input your description!" },
              ]}
            >
              {/* <ReactQuill theme="snow" modules={modules} /> */}
              <SunEditor
                      setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"200px"
                      }}
                    />
            </Form.Item>
          </div>
          <div className="col-md-12 mt-2">
            <Form.Item className="text-end">
              <Button
                htmlType="submit"
                className="form-btn-style"
                disabled={btnLoading ? true : false}
              >
                {btnLoading ? (
                  <span className="me-2">
                    <ButtonLoader />
                  </span>
                ) : (
                  ""
                )}
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AboutContent;

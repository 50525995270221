import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, Upload, message,Image } from "antd";
import instance from "../../../api/api_instance";
import { useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ButtonLoader from "../../../component/ButtonLoader";
import ImageSeoModal from "../../../component/ImageSeoModal";
const { TextArea } = Input;

export default function EditProduct() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);

  const [slugId, setSlugId] = useState("");

  const createSlug = (event) => {
    var convertToLower = event.target.value.toLowerCase();
    var slug = convertToLower.split(/[' '/]+/).join("-");
    form.setFieldsValue({
      slug: slug,
    });
  };

  const [cateagories, setCategories] = useState([]);
  const [prevProductImages, setPrevProductImages] = useState([]);
  const [textEditorData ,setTextEditorData] = useState("")

  const deleteImage = (event, id) => {
    event.preventDefault();
    setPrevProductImages((current) => current.filter((obj) => obj._id !== id));
  };

  const fetchProductData = () => {
    var config = {
      url: `/product/${id}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setPrevProductImages(response?.data?.data?.images ?? []);
            form.setFieldsValue({
              name: response?.data?.data?.name,
              category: response?.data?.data?.category?._id,
              introduction: response?.data?.data?.introduction,
              description: response?.data?.data?.description,
              meta_title: response?.data?.data?.meta_title,
              head_title: response?.data?.data?.head_title,
              meta_description: response?.data?.data?.meta_description,
              slug: response?.data?.data?.product_slug,
              meta_keyword : response?.data?.data?.meta_keyword
            });
            setTextEditorData(response?.data?.data?.description)
            setSlugId(response?.data?.data?.slug_id);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const fetchProductCategory = () => {
    var config = {
      url: `/product-category/all`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            var categoryData = [];
            if (response?.data?.data && response?.data?.data?.length > 0) {
              response?.data?.data?.forEach((element) => {
                categoryData.push({
                  value: element._id,
                  label: element.name,
                });
              });
              setCategories(categoryData ?? []);
            }
          } else {
            // message.error(response.data.message);
          }
        } else {
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };


  useEffect(() => {
    fetchProductCategory();
    fetchProductData();
  }, []);
  const onFinish = async (values) => {
    setBtnLoading(true);
    let formData = new FormData();
    const idArray = prevProductImages.map(obj => obj._id);
    formData.append("product_name", values?.name);
    formData.append("category", values?.category);
    formData.append("introduction", values?.introduction);
    formData.append("prevImgs", JSON.stringify(idArray));
    formData.append("meta_title", values?.meta_title);
    formData.append("head_title", values?.head_title);
    formData.append("slug", values?.slug);
    formData.append("slug_id", slugId);
    formData.append("meta_description", values?.meta_description);
    formData.append("meta_keyword", values?.meta_keyword);
    formData.append("description", values?.description);

    if (values?.images?.fileList && values?.images?.fileList?.length > 0) {
      values?.images?.fileList?.forEach((element) => {
        formData.append("images", element?.originFileObj);
      });
    }
    var config = {
      url: `/product/edit/${id}`,
      method: "POST",
      data: formData,
      headers : {
        "Content-Type" : "multipart/form-data"
      }
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            fetchProductData();
            setBtnLoading(false);
            form.resetFields(["images"]);
            // alert(res.data.message)
          } else {
            setBtnLoading(false);
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          setBtnLoading(false);
          message.error("Something went wrong ! try again later");
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          setBtnLoading(false);
          message.error(error?.response?.data?.message);
        } else {
          setBtnLoading(false);
          message.error(error?.message);
        }
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Edit Product
                {/* <span className="primary-font ms-2">(On website)</span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="card border-0">
            <div className="card-header border-0">
              <p className="mb-0 fw-500 fs-6 secondary-font">Edit Product</p>
            </div>
            <div className="card-body">
              <Form
                name="product_form"
                className="login-form"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="name"
                      label="Product name"
                      rules={[
                        {
                          required: true,
                          message: "Please input product name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Product Name"
                        onChange={createSlug}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="category"
                      label="Product category"
                      rules={[
                        {
                          required: true,
                          message: "Please select product category!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select product category"
                        options={cateagories}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="introduction"
                      label="Product introduction"
                      rules={[
                        {
                          required: true,
                          message: "Please input product introduction!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder="Enter Product introduction"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <Form.Item name="images" label="Product images">
                          <Upload
                            listType="picture-card"
                            accept="image/*"
                            multiple={true}
                            beforeUpload={() => false}
                          >
                            <Button icon={<UploadOutlined />}></Button>
                          </Upload>
                        </Form.Item>
                      </div>

                      {prevProductImages && prevProductImages.length > 0
                        ? prevProductImages?.map((element, index) => {
                            return (
                              <div
                                className="col-auto p-2 text-center"
                                key={index + 1}
                              >
                                <div className="border p-2 rounded">
                                  <Image
                                    src={`${
                                      process.env.REACT_APP_API_URL +
                                      "/" +
                                      element?.path
                                    }`}
                                    alt=""
                                    className="img-fluid img-50"
                                  />
                                  <ImageSeoModal imageId={element?._id}/>
                                  <button
                                    className="btn  btn-sm  fw-500"
                                    onClick={(event) =>
                                      deleteImage(event, element?._id)
                                    }
                                  >
                                    <AiOutlineDelete className="fs-4 font-red" />
                                  </button>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_title"
                      label="Meta title"
                      rules={[
                        { required: true, message: "Please input meta title!" },
                      ]}
                    >
                      <Input placeholder="Enter Meta Title" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_keyword"
                      label="Meta keyword"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta keyword!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Meta keyword" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_description"
                      label="Meta description"
                      rules={[
                        { required: true, message: "Please input your name!" },
                      ]}
                    >
                      <TextArea placeholder="Enter Meta Description" rows={5} />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="head_title"
                      label="Head title"
                      rules={[
                        { required: true, message: "Please input head title!" },
                      ]}
                    >
                      <Input placeholder="Enter Head Title" />
                    </Form.Item>
                    <Form.Item
                      name="slug"
                      label="Product slug"
                      rules={[
                        {
                          required: true,
                          pattern: /[a-zA-Z0-9\-\_]$/,
                          message:
                            "Only characters ,number and underscore is allowed in slug",
                          // message: "Please input product slug!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Product Slug" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    name="description"
                    label="Product description"
                    // rules={[
                    //   {
                    //     required: editorState ? false : true,
                    //     message: "Please input product description!",
                    //   },
                    // ]}
                  >
                  <SunEditor
                      setContents = {textEditorData}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"150px"
                      }}
                    />
                    {/* <ReactQuill theme="snow" modules={modules} /> */}
                  </Form.Item>
                </div>
                <div className="col-md-12 mt-2">
                  <Form.Item className="text-end">
                    <Button
                      htmlType="submit"
                      className="form-btn-style"
                      disabled={btnLoading ? true : false}
                    >
                      {btnLoading ? (
                        <span className="me-2">
                          <ButtonLoader />
                        </span>
                      ) : (
                        ""
                      )}
                      Save
                    </Button>
                    {/* <Button htmlType="submit" className="form-btn-style">
                        Save
                      </Button> */}
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

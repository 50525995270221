import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import About from "../../component/admin_component/home/About";
import Broucher from "../../component/admin_component/home/Broucher";
import FeatureVideo from "../../component/admin_component/home/FeatureVideo";
import LanguageComponent from "../../component/admin_component/home/LanguageComponent";
import Partners from "../../component/admin_component/home/Partners";
import Services from "../../component/admin_component/home/Services";
import Testimonials from "../../component/admin_component/home/Testimonials";
import WebsiteHeader from "../../component/admin_component/home/WebsiteHeader";

function Home() {
  const [activeTab, setActiveTab] = useState("header");

  const navigate = useNavigate();

  const { search } = useLocation();

  const urlparams = new URLSearchParams(search);

  const currentTab = urlparams.get("tab");

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    } else {
      setActiveTab("maincontent");
    }
  }, [currentTab]);

  const renderHomeComponent = () => {
    switch (activeTab) {
      case "maincontent":
        return <WebsiteHeader />;
      case "about":
        return <About />;
      case "services":
        return <Services />;
      case "clients":
        return <Partners />;
      case "brochure":
        return <Broucher />;
      case "testimonials":
        return <Testimonials />;
      case "videos":
        return <FeatureVideo />;
      case "language":
        return <LanguageComponent />;
      default:
        <WebsiteHeader />;
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Home page
                <span className="primary-font">(On website)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12 ">
          <ul className="tab-list px-2 bg-white py-3 rounded">
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "maincontent" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=maincontent`)}
              >
                Main content
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "about" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=about`)}
              >
                About
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "services" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=services`)}
              >
                Services
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "clients" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=clients`)}
              >
                Clients
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "testimonials" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=testimonials`)}
              >
                Testimonials
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "brochure" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=brochure`)}
              >
                Brochure
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "videos" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=videos`)}
              >
                Featured Videos
              </span>
            </li>
            <li>
              <span
                className={`tab-list-menu-item cursor-pointer ${
                  activeTab === "language" ? "active-tab-menu" : ""
                }`}
                onClick={() => navigate(`/admin/?tab=language`)}
              >
                Language
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">{renderHomeComponent()}</div>
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Button, Form, Input, Modal, message } from "antd";
import instance from "../../../api/api_instance";
const { TextArea } = Input;

function Address() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const[visibleMap , setVisibleMap] = useState(0)

  const [addressData, setAddressData] = useState([]);
  const [addressform] = Form.useForm();
  const [editform] = Form.useForm();

  // INSERT MODAL

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // EDIT MODAL

  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const get_address = () => {
    message.loading({key:"loading",content:"Please wait..."})
    var config = {
      url: `/fetch-address`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            message.success({key:"loading",content:"Data Loaded."})
            setAddressData(response?.data?.data);
          } else {
            message.error({key:"loading",content : response.data.message});
          }
        } else {
          message.error({key:"loading",content:response.message});
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({key:"loading",content:error.response.data.message});
        } else {
          message.error({key:"loading",content:error.message});
        }
      });
  };

  const onFinish = (values) => {
    var config = {
      url: `/insert-address`,
      method: "POST",
      data: {
        location: values?.location?.trim(),
        full_address: values?.full_address?.trim(),
        map: values?.map?.trim(),
      },
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            message.success(response.data.message);
            setIsModalOpen(false);
            get_address();

            addressform.resetFields();
            setAddressData(response?.data?.data);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  const deleteAddress = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/delete-address/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (
            
            response.status >= 200 &&
            response.status < 300
          ) {
            if (response.data.status === true) {
              // setIsModalOpen(false);
              get_address();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error.message);
          }
        });
    }
  };

  const showEditModal = (index, record) => {
    editform.setFieldsValue({
      id: index,
      location: record?.location,
      full_address: record?.full_address,
      map: record?.map,
    });
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    get_address();
  }, []);

  const onEdit = (values) => {
    var config = {
      url: `/edit-address/${values?.id}`,
      method: "POST",
      data: {
        location: values?.location?.trim(),
        full_address: values?.full_address?.trim(),
        map: values?.map?.trim(),
      },
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            message.success(response.data.message);

            setIsEditModalOpen(false);
            get_address();
            // setEmailData(response?.data?.data);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  return (
    <div className="col-md-12">
      <div className="card border-0">
        <div className="card-header border-bottom d-flex justify-content-between py-3">
          <p className="mb-0 fw-500 fs-6">Address</p>
          <button
            className="mb-0 primary-font border-0 bg-transparent"
            onClick={showModal}
          >
            <RiAddCircleLine className="fs-5 primary-font" />
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                {/* ADDRESS ROW */}

                {addressData && addressData?.length > 0
                  ? addressData.map((element, index) => {
                      return (
                        <div
                          key={index + 1}
                          className={`col-md-12 border-bottom cursor-pointer py-2 ${visibleMap == index ? 'bg-light':""}`} onClick={()=>{setVisibleMap(index)}}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="secondary-font fw-500 fs-6">
                              {element?.location ?? ""}
                            </p>
                            <div>
                              <button
                                className="btn btn-sm d-inline-flex fs-6 cursor-pointer secondary-font text-end"
                                onClick={() => showEditModal(index, element)}
                              >
                                <AiOutlineEdit />
                              </button>
                              <button
                                className="btn btn-sm d-inline-flex fs-6 primary-font text-end"
                                onClick={() => deleteAddress(index)}
                              >
                                <AiOutlineDelete />
                              </button>
                            </div>
                          </div>
                          <p>{element?.full_address ?? ""}</p>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="col-md-8">
                    {
                      addressData && addressData?.length > 0 ?
                      <div className=" contact-address-map"  dangerouslySetInnerHTML={{__html:addressData[visibleMap]?.map}}></div> :""
                    }
              
                {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.4803049102593!2d72.99667731546273!3d26.278525193427583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418c29559fe7d9%3A0x94ee7ce89aa90d3a!2sBombay%20Motors%20Cir%2C%20Bombay%20Motor%20Circle%2C%20Jodhpur%2C%20Rajasthan%20342003!5e0!3m2!1sen!2sin!4v1667983722601!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe> */}
              
            </div>
          </div>
        </div>
      </div>

      {/* INSERT MODAL */}
      <Modal
        title="Add address"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          form={addressform}
          requiredMark={false}
        >
          <Form.Item
            name="location"
            label="Location Heading"
            rules={[
              {
                required: true,
                message: "Please input your location heading!",
              },
            ]}
          >
            <Input placeholder="Enter your location heading" />
          </Form.Item>
          <Form.Item
            name="full_address"
            label="Full Address"
            rules={[
              { required: true, message: "Please input your full address!" },
            ]}
          >
            <TextArea rows={2} placeholder="Enter your address" />
          </Form.Item>
          <Form.Item
            name="map"
            label="Embed Map"
            rules={[{ required: true, message: "Please embed your map!" }]}
          >
            <TextArea rows={4} placeholder="Embed your map" />
          </Form.Item>

          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        title="Edit address"
        visible={isEditModalOpen}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        width={1000}
        footer={null}
      >
        <Form
          name="edit_address"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onEdit}
          layout="vertical"
          form={editform}
          requiredMark={false}
        >
          <Form.Item
            name="id"
            rules={[{ required: true, message: "Please input your id!" }]}
            className="d-none"
          >
            <Input placeholder="id" className="d-none" disabled />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location Heading"
            rules={[
              {
                required: true,
                message: "Please input your location heading!",
              },
            ]}
          >
            <Input placeholder="Enter your location heading" />
          </Form.Item>
          <Form.Item
            name="full_address"
            label="Full Address"
            rules={[
              { required: true, message: "Please input your full address!" },
            ]}
          >
            <TextArea rows={2} placeholder="Enter your address" />
          </Form.Item>
          <Form.Item
            name="map"
            label="Embed Map"
            rules={[{ required: true, message: "Please embed your map!" }]}
          >
            <TextArea rows={4} placeholder="Embed your map" />
          </Form.Item>

          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Edit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Address;

import React, { useEffect } from "react";
import { Form, Input, Button, Upload, message,Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import instance from "../../../api/api_instance";
import { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ImageSeoModal from "../../ImageSeoModal";


export default function About() {
  const [form] = Form.useForm();
  const[aboutFormData , setAboutFormData] = useState({})
  const [textEditorData , setTextEditorData] = useState("")
 
  const get_home_form_data = () => {
    message.loading({key:"loading",content:"Please wait..."})
    var config = {
      url: `/home-header`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            setAboutFormData(response.data.data)
            setTextEditorData(response.data.data.about_description)
            form.setFieldsValue({
              heading : response?.data?.data?.about_heading,
              description : response?.data?.data?.about_description,
            })
            form.resetFields(["about_img"])
            message.success({key:"loading",content:"Data Loaded."})
          } else {
            message.error({key:"loading",content:response.data.message})
            // message.error(response.data.message);
          }
        } else {
          message.error({key:"loading",content:response.message})
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({key:"loading",content:error.response.data.message})
        } else {
          message.error({key:"loading",content:error.message})
        }
      });
  };

  useEffect(()=>{
    get_home_form_data()
  },[])
  const onFinish = (values) => {
    message.loading({key:"loading",content:"Please wait..."})
    let formData = new FormData();
    formData.append("heading", values?.heading);
    formData.append("description", values?.description);
    if (values?.about_img) {
      formData.append("about_img", values?.about_img?.fileList[0]?.originFileObj);
    }
    var config = {
      url: `/insert-home-about`,
      method: "POST",
      data: formData,
      headers : {
        "Content-Type": "multipart/form-data",
      }
    };
    instance(config)
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300
        ) {
          if (response.data.status) {
            message.success({ key:"loading",content: response.data.message, key: "loading" });
            get_home_form_data()
            // alert(res.data.message)
          } else {
            message.error({key:"loading", content: response.data.message, key: "loading" });
          }
        } else {
          message.error({key:"loading",content:"Something went wrong! try again later"});
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({key:"loading",content:error?.response?.data?.message});
        } else {
          message.error({key:"loading",content:error?.message});
        }
      });
  };
  return (
    <div className="card border-0 mb-5">
      <div className="card-header border-0">
        <p className="mb-0 fw-500 fs-6">About us</p>
      </div>
      <div className="card-body">
        <Form
          name="aboutus_form"
          className="login-form"
          initialValues={{ remember: true }}
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          requiredMark={false}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                label="Heading"
                name="heading"
                rules={[
                  { required: true, message: "Please input your heading!" },
                ]}
              >
                <Input placeholder="Enter Heading" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please input your description!" },
                ]}
              >
               <SunEditor
                      setContents={textEditorData} // You can set the initial content here
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"500px"
                      }}
                    />
                 {/* <ReactQuill theme="snow"  modules={modules} /> */}
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="Image"
                name="about_img"
                rules={[
                  {
                    required: aboutFormData?.about_image?.path ? false : true,
                    message: "Please upload about image!",
                  },
                ]}
              >
                <Upload multiple={false} accept="image/*" beforeUpload={() => false}>
                  <Button icon={<UploadOutlined />} >
                    Click to Upload Image
                  </Button>
                </Upload>
              </Form.Item>
              {
               aboutFormData?.about_image?.path?._id ?
               <div>
               <Image src={`${process.env.REACT_APP_API_URL+"/"+aboutFormData?.about_image?.path}`} alt="" className="img-fluid form-banner-img" /> <ImageSeoModal imageId={aboutFormData?.about_image?._id}/> </div> :""
              }
            </div>
          </div>
          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, message } from "antd";
import { RiAddCircleLine } from "react-icons/ri";
import { AiOutlineDelete, AiOutlineMail } from "react-icons/ai";
import instance from "../../../api/api_instance";

function ContactEmail() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteEmail = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/delete-email/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              setIsModalOpen(false);
              get_email();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error.message);
          }
        });
    }
  };

  const onFinish = (values) => {
    var config = {
      url: `/insert-email`,
      method: "POST",
      data: { email: values?.email?.trim() },
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            message.success(response.data.message);
            setIsModalOpen(false);
            get_email();
            form.resetFields();
            // setEmailData(response?.data?.data);
          } else {
            message.error(response.data.message);
          }
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  const get_email = () => {
    message.loading({ key: "loading", content: "Please wait" });
    var config = {
      url: `/fetch-emails`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setEmailData(response?.data?.data);
            message.success({ key: "loading", content: "Data Loaded" });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  useEffect(() => {
    get_email();
  }, []);

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="card border-0">
        <div className="card-header border-bottom d-flex justify-content-between py-3">
          <p className="mb-0 fw-500 fs-6">Email</p>

          <button
            className="mb-0 primary-font border-0 bg-transparent"
            onClick={showModal}
          >
            <RiAddCircleLine className="fs-5 primary-font" />
          </button>
        </div>
        <div className="card-body">
          <table className="table mb-0">
            <tbody>
            {emailData && emailData?.length > 0
              ? emailData.map((elment, index) => {
                  return (
                    <tr key={index + 1}>
                      <td>
                        <p>{elment}</p>
                      </td>
                      <td className="text-end">
                        <button
                          className=" fs-5 d-inline-flex btn btn-sm primary-font text-end"
                          onClick={() => deleteEmail(index)}
                        >
                          <AiOutlineDelete />
                        </button>
                      </td>
                    </tr>
                  );
                })
              : null}</tbody>
          </table>
        </div>
      </div>
      <Modal
        title="Add email"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your email address!",
              },
            ]}
          >
            <Input
              prefix={<AiOutlineMail className="site-form-item-icon" />}
              placeholder="Enter Email"
            />
          </Form.Item>

          <Form.Item className="text-end">
            <Button
              // type="danger"
              htmlType="submit"
              className="form-btn-style"
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ContactEmail;

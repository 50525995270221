import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  message,
  Table,
  Image,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import instance from "../../../api/api_instance";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import ButtonLoader from "../../ButtonLoader";
import ImageSeoModal from "../../ImageSeoModal";
const { TextArea } = Input;

export default function Testimonials() {
  const [form] = Form.useForm();
  const [edit_form] = Form.useForm();
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalImg, setEditModalImg] = useState("");

  const showModal = (record) => {
    edit_form.setFieldsValue({
      id: record?._id,
      client_name: record?.client_name,
      company_name: record?.company_name,
      client_rating:record?.client_rating,
      client_review: record?.client_review
    });
    setEditModalImg(record?.client_image ?? "");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditModalImg("");
  };

  // INSERT TESTIMONIALS
  const onFinish = (values) => {
    let formData = new FormData();
    formData.append("client_name", values?.client_name);
    formData.append("client_rating", values?.client_rating);
    formData.append("company_name", values?.company_name);
    formData.append("client_review", values?.client_review);
    if (values?.client_image) {
      formData.append(
        "client_image",
        values?.client_image?.fileList[0]?.originFileObj
      );
    }

    var config = {
      url: `/insert-testimonials`,
      method: "POST",
      headers : {
        "Content-Type" : "mulitpart/form-data"
      },
      data: formData,
    };
    instance(config)
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300
        ) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            setBtnLoading(false)
            get_all_testimonials();
            form.resetFields();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
            setBtnLoading(false)
          }
        } else {
          message.error("Something went wrong! try again later");
          setBtnLoading(false)
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
          setBtnLoading(false)
        } else {
          message.error(error?.message);
          setBtnLoading(false)
        }
      });
  };

// EDIT TESTIMONIALS
const onEditTestimonials = (values) => {
  let formData = new FormData();
  formData.append("client_name", values?.client_name);
  formData.append("client_rating", values?.client_rating);
  formData.append("company_name", values?.company_name);
  formData.append("client_review", values?.client_review);
  if (values?.client_image) {
    formData.append(
      "client_image",
      values?.client_image?.fileList[0]?.originFileObj
    );
  }
  var config = {
    url: `/edit-testimonial/${values?.id}`,
    method: "POST",
    data: formData,
    headers : {
      "Content-Type" : "multipart/form-data"
    }
  };
  instance(config)
    .then((response) => {
      if (
        response?.status >= 200 &&
        response?.status < 300
      ) {
        if (response.data.status) {
          message.success({ content: response.data.message, key: "loading" });
          get_all_testimonials();
          setEditModalImg('')
          handleCancel()
          edit_form.resetFields(['client_image']);
          // alert(res.data.message)
        } else {
          message.error({ content: response.data.message, key: "loading" });
        }
      } else {
        message.error("Something went wrong! try again later");
      }
    })
    .catch((error) => {
      // Server can't be reached!
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message);
      } else {
        message.error(error?.message);
      }
    });
};
// FETCH TESTIMONIALS
  const get_all_testimonials = () => {
    message.loading({key:"loading",content:"Please wait.."})
    var config = {
      url: `/testimonials`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 &&
          response.status < 300
        ) {
          if (response.data.status === true) {
            setTestimonialsData(response.data.data);
            message.success({key:"loading",content:"Data Loaded."})
          } else {
            message.error({key:"loading",content:response.data.message});
          }
        } else {
          message.error({key:"loading",content:response.message});
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({key:"loading",content:error.response.data.message});
        } else {
          message.error({key:"loading",content:error.message});
        }
      });
  };

  useEffect(() => {
    get_all_testimonials();
  }, []);
  const deleteTestimonial = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/delete-testimonial/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (
            response.status >= 200 &&
            response.status < 300
          ) {
            if (response.data.status === true) {
              get_all_testimonials();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const columns = [
    {
      title: "_id",
      key: "_id",
      dataIndex: "_id",
      // render: (text, object, index) => {
      //   return index + 1;
      // },
      hidden : true
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Client Rating",
      dataIndex: "client_rating",
      key: "client_rating",
    },
    {
      title: "Client Image",
      dataIndex: "client_image",
      key: "client_image",
      render: (text, record) => (
        <div key={record._id} className="">
          {record?.client_image ? (
            <div>
            <Image
              src={`${
                process.env.REACT_APP_API_URL + "/" + record?.client_image?.path
              }`}
              alt=""
              className="img-fluid table-image"
            />
            <ImageSeoModal imageId={record?.client_image?._id}/>
            </div>

         
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <div key={record._id} className="d-md-flex">
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => showModal(record)}
          >
            <FiEdit />
          </Button>

          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteTestimonial(record._id)}
          >
            <AiOutlineDelete
              className="font-red "
              style={{ cursor: "pointer" }}
            />
          </Button>
        </div>
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card border-0">
          <div className="card-header border-0">
            <p className="mb-0 fw-500 fs-6">Testimonials</p>
          </div>
          <div className="card-body">
            <Form
              name="aboutus_form"
              className="login-form"
              initialValues={{ remember: true }}
              form={form}
              layout={"vertical"}
              onFinish={onFinish}
              requiredMark={false}
            >
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Client Name"
                    name="client_name"
                    rules={[
                      { required: true, message: "Please input client name!" },
                    ]}
                  >
                    <Input placeholder="Enter Client Name" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="client_rating"
                    label="Client Rating"
                    rules={[
                      {
                        required: true,
                        message: "Please select client rating!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select social media"
                      options={[
                        {
                          value: "1",
                          label: "1",
                        },
                        {
                          value: "2",
                          label: "2",
                        },
                        {
                          value: "3",
                          label: "3",
                        },
                        {
                          value: "4",
                          label: "4",
                        },
                        {
                          value: "5",
                          label: "5",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    label="Company Name"
                    name="company_name"
                    rules={[
                      { required: true, message: "Please input company name!" },
                    ]}
                  >
                    <Input placeholder="Enter Company Name" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    label="Client Review"
                    name="client_review"
                    rules={[
                      {
                        required: true,
                        message: "Please input client review!",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Enter review" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="Client Image"
                    name="client_image"
                    rules={[
                      {
                        required: true,
                        message: "Please upload client image!",
                      },
                    ]}
                  >
                    <Upload
                      listType="picture-card"
                      accept="image/*"
                      multiple={false}
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
              <Form.Item className="text-end">
              <Button
                htmlType="submit"
                className="form-btn-style"
                disabled={btnLoading ? true : false}
              >
                {btnLoading ? (
                  <span className="me-2">
                    <ButtonLoader />
                  </span>
                ) : (
                  ""
                )}
                Add
              </Button>
                {/* <Button htmlType="submit" className="form-btn-style">
                  Add
                </Button> */}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="col-md-12 my-3">
        <div className="card border-0">
          <div className="card-body table-responsive p-0">
            <Table dataSource={testimonialsData} columns={columns} rowKey="_id"/>
          </div>
        </div>
      </div>

      <Modal
        title="Edit testimonials"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="aboutus_form"
          className="login-form"
          initialValues={{ remember: true }}
          form={edit_form}
          layout={"vertical"}
          onFinish={onEditTestimonials}
          requiredMark={false}
        >
          <div className="row">
          <div className="col-md-12">
              <Form.Item
                name="id"
                rules={[{ required: true, message: "Please input your id!" }]}
                className="d-none"
              >
                <Input type="hidden" placeholder="Id" className="d-none" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="Client Name"
                name="client_name"
                rules={[
                  { required: true, message: "Please input client name!" },
                ]}
              >
                <Input placeholder="Enter Client Name" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                name="client_rating"
                label="Client Rating"
                rules={[
                  {
                    required: true,
                    message: "Please select client rating!",
                  },
                ]}
              >
                <Select
                  placeholder="Select social media"
                  options={[
                    {
                      value: "1",
                      label: "1",
                    },
                    {
                      value: "2",
                      label: "2",
                    },
                    {
                      value: "3",
                      label: "3",
                    },
                    {
                      value: "4",
                      label: "4",
                    },
                    {
                      value: "5",
                      label: "5",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[
                  { required: true, message: "Please input company name!" },
                ]}
              >
                <Input placeholder="Enter Company Name" />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                label="Client Review"
                name="client_review"
                rules={[
                  {
                    required: true,
                    message: "Please input client review!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Enter review" />
              </Form.Item>
            </div>
            <div className="col-auto">
              <Form.Item
                label="Client Image"
                name="client_image"
              >
                <Upload
                  listType="picture-card"
                  accept="image/*"
                  multiple={false}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}></Button>
                </Upload>
              </Form.Item>
             
            </div>
            <div className="col-md-6 d-flex align-items-center">
            <Image
                src={`${process.env.REACT_APP_API_URL + "/" + editModalImg?.path}`}
                alt=""
                className="img-fluid img-100"
              />
            </div>
          </div>
          <Form.Item className="text-end">
            <Button htmlType="submit" className="form-btn-style">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

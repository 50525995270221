import React from "react";
import { Dropdown, Menu, message } from "antd";
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiKeyFill } from "react-icons/ri";
import { FiUser, FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from "axios";
import { ActionTypes } from "../redux/constants/ActionTypes";
import { useEffect } from "react";


function Navbar() {


  const dispatch = useDispatch()

  const navigate = useNavigate();

  const sidebar = useSelector(state => state.sidebar.sidebar_status)
  const adminData = useSelector(state => state?.auth?.admin)
 
 

  const onClickHandler = () => {
    if (sidebar === "close") {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "open" })
    }
    else {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" })
    }
  }
  useEffect(() => {
    function handleWindowResize() {
      if(window?.innerWidth < 1200){
        dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" }) 
      }else{
        dispatch({ type: "SET_SIDEBAR_STATUS", payload: "open" })
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const logOutHandler = (event) => {
    event.preventDefault()
    if (window.confirm("Are You sure to logout")) {

      axios.post(`${process.env.REACT_APP_API_URL}/adminapi/logout`, { id: adminData._id }).then(response => {

        if ( response.status >= 200 && response.status < 300) {
          if (response.data.status) {
            localStorage.clear();
            dispatch({ type: ActionTypes.ADMIN_LOGOUT });
            navigate("/login");
          }
          else {
            message.error(response.data.message)
          }
        }
      })
        .catch((error) => {
          if (error.response.data.message) {
            message.error(error.response.data.message)
          } else {
            message.error(error.message)
          }
        });

    }
  }


  const notificationMenu = (

    <Menu style={{ width: "350px", position: "relative" }}>
      <div className="dd-menu-title m-0">
        <p className="text-center mb-0">Notifications</p>
      </div>
      <Menu.Item key={1}>
        <div className="container">
          <div className="row">
            <div className="col-sm-3 dd-avatar d-flex align-items-center ">
              <img src="images/adminIcon.jpg" alt="notificationImage" />
            </div>
            <div className="col-sm-9 px-0">
              <div className="d-flex justify-content-between"><span className="text-capitalize fw-500">Lorem ipsum</span><span className="fs-sm">a day ago</span></div>
              <p className="dd-content mb-2 fs-sm textcolor-secondary">Lorem ipsum dolor sit amet, consectetur adipisicing elitm</p>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Menu.Item>
      <Menu.Item key={2} >
        <div className="container">
          <div className="row">
            <div className="col-sm-3 dd-avatar d-flex align-items-center ">
              <img src="images/adminIcon.jpg" alt="notificationImage" />
            </div>
            <div className="col-sm-9 px-0">
              <div className="d-flex justify-content-between"><span className="text-capitalize fw-500">Lorem ipsum</span><span className="fs-sm ">a day ago</span></div>
              <p className="dd-content fs-sm mb-2 textcolor-secondary">Lorem ipsum dolor sit amet, fdf fdsfsd fdsf adipisicing elitm</p>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Menu.Item>
      <Menu.Item key={3}>
        <div className="container">
          <div className="row">
            <div className="col-sm-3 dd-avatar d-flex align-items-center ">
              <img src="images/adminIcon.jpg" alt="notificationImage" />
            </div>
            <div className="col-sm-9 px-0">
              <div className="d-flex justify-content-between"><span className="text-capitalize fw-500">Lorem ipsum</span><span className="fs-sm ">a day ago</span></div>
              <p className="dd-content fs-sm mb-2 textcolor-secondary">Lorem ipsum dolor sit amet, consectetur adipisicing elitm</p>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Menu.Item>

    </Menu>

  );

  const profileMenu = (
    <Menu style={{ width: "230px" }}>
      <div className="container">
        <div className="row dd-profile-info">
          {/* <div className="col-sm-3 d-flex align-items-center admin-dd-avatar">
            <img src={adminData?.profilePic ? process.env.REACT_APP_API_URL + "/" + adminData.profilePic : "/images/Default-registration-profile.png"} alt="" className="img-fluid" />
          </div> */}
          <div className="col-sm-12 justify-content-center text-center d-flex align-items-center">
            <div>
              <p className="mb-0 ">
                <span className="fw-500 text-capitalize d-block">{adminData.name ? adminData.name : ''}</span>
                <span className="fw-500 textcolor-secondary">{adminData.email ? adminData.email : ''}</span>
              </p>
            </div>


          </div>
        </div>

      </div>
      <div>
      </div>
      <Menu.Item key={1}>
        <Link to="/admin/profile" ><FiUser /><span className="ms-3 fs-sm fw-500 textcolor-secondary">My Profile</span> </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link to="/admin/changepassword" ><RiKeyFill /><span className="ms-3 fs-sm fw-500 textcolor-secondary">Change Password</span> </Link>
      </Menu.Item>
      <Menu.Item key={3}>
        <p onClick={logOutHandler} ><FiLogOut className="text-danger" /><span className="ms-3 fs-sm fw-500 text-danger">Log Out</span> </p>
      </Menu.Item>

      {/* <Menu.Item key={2}>
      <Link path = "/">Change Password</Link>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <nav className="container-fluid">
      <div className="d-flex justify-content-between h-100  align-items-center">
        <div className="">
          <span className="side-collapse" >
            <AiOutlineMenuFold className="fs-4" onClick={onClickHandler} />
          </span>
        </div>
        <div className="text-end">
          <ul className="list-inline list-unstyled mb-0">
            <li className="list-inline-item me-4">
              <Dropdown
                overlay={notificationMenu}
                placement="bottomRight"
                trigger={["click"]}
                arrow
              >
                <span className="border-0 fs-4 " style={{ background: "transparent" }}><IoMdNotificationsOutline /></span>
              </Dropdown>
            </li>

            <li className="list-inline-item ">
              <Dropdown
                overlay={profileMenu}
                placement="bottomRight"
                trigger={["click"]}
                arrow
              >
                <span className="border-0 fs-4" style={{ background: "transparent" }}>
                  <span className="">
                    {/* <img src="images/adminIcon.jpg" alt="adminicon" className="profile-icon-img" /> */}
                    <img src={adminData?.profilePic ? process.env.REACT_APP_API_URL + "/" + adminData.profilePic : "/images/Default-registration-profile.png"} alt="" className="profile-icon-img" />
                  </span>
                </span>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

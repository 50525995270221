import React from "react";
import { Form, Input, message, Button } from "antd";
import instance from "../../api/web_instance";
const { TextArea } = Input;

function EnquiryForm() {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    const enquiry_data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      comment: values.comment,
      //   enquiry_for: values.enquiry_for,
    };
    var config = {
      url: `/enquiry/create`,
      method: "POST",
      data: enquiry_data,
    };

    instance(config)
      .then((response) => {
        console.log(response);

        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({
              content: response.data.message,
              key: "loading",
            });
            form.resetFields();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error("Something went wrong! try again later");
        }
      })
      .catch((error) => {
        console.log(error);

        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
      });
  };
  return (
    <div>
      {" "}
      <Form
        className="login-form"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required!",
            },
          ]}
        >
          <Input placeholder="Your Name" className="antd-input-custom-style" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid email address!",
            },
            {
              // Custom validator to block spam domains
              validator: (_, value) => {
                const spamDomains = ["example.com", "fake.com"]; // List of spam domains
                const domain = value?.split("@")[1];
                if (spamDomains.includes(domain)) {
                  return Promise.reject(
                    new Error(
                      "This domain is blocked. Please use a different email address."
                    )
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="example@gmail.com"
            className="antd-input-custom-style"
          />
        </Form.Item>
        <Form.Item
          label="Mobile No."
          name="mobile"
          rules={[
            {
              required: true,
              message: "Mobile number is required!",
            },
            {
              pattern: /^\+?[1-9]\d{4,14}$/,
              message:
                "Please enter a valid mobile number (with or without country code)!",
            },
          ]}
        >
          <Input
            placeholder="Your Mobile no"
            className="antd-input-custom-style"
          />
        </Form.Item>

        <Form.Item label="Comment" name="comment">
          <TextArea
            placeholder="Type your comment..."
            rows={5}
            className="antd-input-custom-style"
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" className="contact-form-btn">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EnquiryForm;

import React from 'react'

function Footer() {
    return (
        <footer className="container-fluid footer-style py-4">
          <div className="row">
              <div className="col-md-12">
                    <p className='mb-0'>© Copyright - Oliver Rubber Industries LLP {new Date().getFullYear()} | Design And Developed By - Techoniq Fusion IT Solutions PVT. LTD.- All rights reserved.</p>
              </div>
          </div>  
        </footer>
    )
}

export default Footer
 
import React, { useState } from "react";
import { Table, Button, message, Pagination, Select, Input } from "antd";
import moment from "moment";

import { AiOutlineDelete, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { BiFilter } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import instance from "../../../api/api_instance";
import { useEffect } from "react";
const { Option } = Select;

export default function Products() {
  const navigate = useNavigate();

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const page = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

  const [productData, setProductData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(page);
  const [filterBy, setFilterBy] = useState("name");
  const [searchQry, setSearchQry] = useState("");

  const deleteProduct = (id) => {
    if (window.confirm("Are you sure to delete")) {
      var config = {
        url: `/product/delete/${id}`,
        method: "GET",
      };
      instance(config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data.status === true) {
              fetchProducts();
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const fetchProducts = () => {
    message.loading({ key: "loading", content: "Loading..." });
    var config = {
      url: `/products?page=${currentPage}&filter_by=${filterBy}&searchQry=${searchQry}`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setProductData(response?.data?.data ?? []);
            setTotalData(response?.data?.totalCount);
            message.success({ key: "loading", content: "Products data fetched successfully." });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        message.error({ key: "loading", content: error.message });
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage, filterBy, searchQry]);

  const columns = [
    {
      title: "S_No",
      key: "sno",
      render: (text, object, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record, index) => (
        // console.log(record.category.name)
        <p key={index + 1}>{record?.category?.name}</p>
      ),
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => (
        <p>{moment(text).format("DD-MM-YYYY , hh:mm:ss")}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "200",
      render: (text, record) => (
        <div
          className="d-md-flex justify-content-center align-items-center "
          key={record._id}
        >
          <Button
            className="btn btn-outline-info btn-sm m-1 fw-500"
            onClick={() => navigate(`/admin/product-details/${record._id}`)}
            // onClick={}
          >
            <AiOutlineEye />
          </Button>
          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => navigate(`/admin/edit-product/${record._id}`)}
          >
            <FiEdit className="text-primary" />
          </Button>

          <Button
            className="btn btn-outline-primary btn-sm m-1 fw-500"
            onClick={() => deleteProduct(record._id)}
          >
            <AiOutlineDelete className="font-red" />
          </Button>
        </div>
      ),
    },
  ];

  const handlePagination = (page) => {
    if (page > 0 && page <= totalData) {
      currentPage !== page && navigate(`/admin/products?page=${page}`);
      setCurrentPage(page);
      //       fetchData();
    }
    // setCurrentPage(pagination);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Manage Product
                {/* <span className="primary-font ms-2">(On website)</span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <div className="card bg-white border-0">
            <div className="row card-body">
              <div className="col-md-3 my-1 d-flex align-items-center">
                <label className="custom-label">
                  <BiFilter className="fs-3" />
                </label>
                <Select
                  onChange={(value) => setFilterBy(value)}
                  placeholder="Filter By:"
                  style={{ width: "100%" }}
                  value={filterBy}
                >
                  <Option value="name">Name</Option>
                  <Option value="category.name">Category</Option>
                </Select>
              </div>
              <div className="col-md-3 my-1">
                {/* <label className="custom-label">Type Query :</label> */}
                <Input
                  type="text"
                  prefix={<AiOutlineSearch />}
                  placeholder="Type Search Query..."
                  onChange={(e) => setSearchQry(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12 ">
          <div className="bg-white table-responsive">
            <Table
              dataSource={productData}
              columns={columns}
              rowKey="_id"
              pagination={false}
            />

            <Pagination
              className="text-center my-3"
              current={currentPage}
              pageSize={10}
              onChange={handlePagination}
              total={totalData}
              itemRender={itemRender}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import instance from "../../../api/api_instance";
import ImageSeoModal from "../../ImageSeoModal";
const { TextArea } = Input;

export default function ContactHeading() {
  const [form] = Form.useForm();
  const [contactUsImg, setContactUsImg] = useState(null);

  const onFinish = (values) => {
    let formData = new FormData();
    formData.append("contact_heading", values?.contact_heading);
    formData.append("contact_sub_heading", values?.contact_sub_heading);
    if (values?.contact_us_img) {
      formData.append(
        "contact_us_img",
        values?.contact_us_img?.fileList[0]?.originFileObj
      );
    }
    var config = {
      url: `/insert-headings`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    instance(config)
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          if (response.data.status) {
            message.success({ content: response.data.message, key: "loading" });
            form.resetFields(["contact_us_img"]);
            get_data();
            // alert(res.data.message)
          } else {
            message.error({ content: response.data.message, key: "loading" });
          }
        } else {
          message.error({
            key: "loading",
            content: "Something went wrong! try again later",
          });
        }
      })
      .catch((error) => {
        // Server can't be reached!
        if (error?.response?.data?.message) {
          message.error({
            key: "loading",
            content: error?.response?.data?.message,
          });
        } else {
          message.error({ key: "loading", content: error?.message });
        }
      });
  };

  const get_data = () => {
    message.loading({ key: "loading", content: "Please wait..." });
    var config = {
      url: `/fetch-headings`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            setContactUsImg(response?.data?.data?.contact_us_image);
            form.setFieldsValue({
              contact_heading: response?.data?.data?.contact_heading,
              contact_sub_heading: response?.data?.data?.contact_sub_heading,
            });
            message.success({ key: "loading", content: "Data Loaded" });
          } else {
            message.error({ key: "loading", content: response.data.message });
          }
        } else {
          message.error({ key: "loading", content: response.message });
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error({
            key: "loading",
            content: error.response.data.message,
          });
        } else {
          message.error({ key: "loading", content: error.message });
        }
      });
  };

  useEffect(() => {
    get_data();
  }, []);
  return (
    <div className="col-12 my-2">
      <div className="card border-0">
        <div className="card-header border-0">
          <p className="mb-0 fw-500 fs-6">Headings</p>
        </div>
        <div className="card-body">
          <Form
            name="contac_heading_form"
            className="login-form"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="contact_heading"
                  label="Main Heading"
                  rules={[
                    {
                      required: true,
                      message: "Please input heading!",
                    },
                  ]}
                >
                  <TextArea rows="3" placeholder="Enter Heading" />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="contact_sub_heading"
                  label="Sub Heading"
                  rules={[
                    {
                      required: true,
                      message: "Please input sub heading!",
                    },
                  ]}
                >
                  <TextArea rows="3" placeholder="Enter Sub Heading" />
                </Form.Item>
              </div>
              <div className="col-md-auto">
                <Form.Item
                  label="Image"
                  name="contact_us_img"
                  rules={[
                    {
                      required: contactUsImg ? false : true,
                      message: "Please upload contact us image!",
                    },
                  ]}
                >
                  <Upload
                    multiple={false}
                    accept="image/*"
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Logo
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
              <div className="col-md-4">
              {
                console.log(contactUsImg?.path)
              }
                {contactUsImg?.path ? (
                  <div className="border p-2">
                    <Image
                      src={`${
                        process.env.REACT_APP_API_URL + "/" + contactUsImg?.path
                      }`}
                      alt=""
                      className="img-fluid form-banner-img"
                    />
                    <ImageSeoModal imageId={contactUsImg?._id}/>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-2">
                <Form.Item className="text-end">
                  <Button htmlType="submit" className="form-btn-style">
                    Save
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MasterLayout from "./layout/MasterLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/main.scss";
import "antd/dist/antd.min.css";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import ChangePassword from "./pages/adminpanel/ChangePassword";
import { useSelector } from "react-redux";
import AdminProfile from "./pages/adminpanel/AdminProfile";
import ContactUs from "./pages/adminpanel/ContactUs";
import AddProduct from "./pages/adminpanel/product/AddProduct";
import ProductCategory from "./pages/adminpanel/product/ProductCategory";
import EditProduct from "./pages/adminpanel/product/EditProduct";
import Products from "./pages/adminpanel/product/Products";
import ProductDetails from "./pages/adminpanel/product/ProductDetails";
import Home from "./pages/adminpanel/Home";
// import About from "./component/admin_component/home/About";
import AboutUs from "./pages/adminpanel/about/AboutUs";
import ManageSeo from "./pages/adminpanel/seo/ManageSeo";
import HomePage from "./pages/web/HomePage";
import AboutUsPage from "./pages/web/AboutUsPage";
import ProductList from "./pages/web/ProductList";
import ContactUsPage from "./pages/web/ContactUsPage";
import ProductDetailsPage from "./pages/web/ProductDetailsPage";
import EnquiryManage from "./pages/adminpanel/EnquiryManage";
import WebsiteLayout from "./pages/web/WebsiteLayout";
import PageNotFound from "./component/PageNotFound";
import AddBlog from "./pages/adminpanel/blog/AddBlog";
import EditBlog from "./pages/adminpanel/blog/EditBlog";
import BlogPage from "./pages/web/BlogPage";
import BlogDetailsPage from "./pages/web/BlogDetailsPage";
import Blog from "./pages/adminpanel/blog/Blog";
import { useEffect } from "react";
import AddPage from "./pages/adminpanel/pages/AddPage";
import AllPages from "./pages/adminpanel/pages/AllPages";
import EditPage from "./pages/adminpanel/pages/EditPage";
import DynamicPage from "./pages/web/DynamicPage";

export const websiteRoutes = () => {
  return (
    <Route exact path="/" element={<WebsiteLayout />}>
      <Route path="/" element={<HomePage />} />
      <Route exact path="/about-us" element={<AboutUsPage />} />
      <Route exact path="/products/:slug" element={<ProductList />} />
      <Route
        exact
        path="/product-details/:slug"
        element={<ProductDetailsPage />}
      />
      <Route exact path="/contact-us" element={<ContactUsPage />} />
      <Route exact path="/blog" element={<BlogPage />} />
      <Route exact path="/blog/:slug" element={<BlogDetailsPage />} />
      <Route path="/:slug" element={<DynamicPage />}/>
    </Route>
  );
};
function App() {
  const loggedIn = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);



  return (
    <BrowserRouter>
      <Routes>
          {websiteRoutes()}
        <Route
          path="/login"
          element={!loggedIn ? <Login /> : <Navigate to="/admin" />}
        />
        <Route
          path="/forgot-password"
          element={!loggedIn ? <ForgotPassword /> : <Navigate to="/admin" />}
        />
        <Route
          path="/reset_password/:id/:date"
          element={!loggedIn ? <ResetPassword /> : <Navigate to="/admin" />}
        />
        <Route
          exact
          path="/admin"
          element={loggedIn ? <MasterLayout /> : <Navigate exact to="/login" />}
        >
          <Route path="/admin" element={<Home />} />
          <Route path="/admin/profile" element={<AdminProfile />} />
          <Route path="/admin/contact-us" element={<ContactUs />} />
          <Route path="/admin/about-us" element={<AboutUs />} />
          <Route path="/admin/changepassword" element={<ChangePassword />} />
          <Route path="/admin/seo" element={<ManageSeo />} />
          <Route path="/admin/blog/add" element={<AddBlog />} />
          <Route path="/admin/blog" element={<Blog />} />
          <Route path="/admin/blog/edit/:id" element={<EditBlog />} />
          <Route path="/admin/page/add" element={<AddPage />} />
          <Route path="/admin/pages" element={<AllPages />} />
          <Route path="/admin/page/edit/:id" element={<EditPage />} />
          <Route path="/admin/enquiry" element={<EnquiryManage />} />
          <Route path="/admin/add-product" element={<AddProduct />} />
          <Route path="/admin/products" element={<Products />} />
          <Route path="/admin/edit-product/:id" element={<EditProduct />} />
          <Route path="/admin/product-category" element={<ProductCategory />} />
          <Route
            path="/admin/product-details/:id"
            element={<ProductDetails />}
          />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import axios from "axios"; 

const instance = axios.create({
  baseURL : process.env.REACT_APP_API_BASE_URL+"/web",
  // headers:{
  //   'Access-Control-Allow-Origin' : '*',
  //   'Content-Type' : 'application/x-www-from-urlencoded'
  // }
});

export default instance;
import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, Upload, message } from "antd";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import instance from "../../../api/api_instance";
import ButtonLoader from "../../../component/ButtonLoader";

const { TextArea } = Input;

export default function AddProduct() {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);

  const createSlug = (event) => {
    var convertToLower = event.target.value.toLowerCase();
    var slug = convertToLower.split(/[' '/]+/).join("-");
    form.setFieldsValue({
      slug: slug,
    });
  };

  const [cateagories, setCategories] = useState([]);

  const fetchProductCategory = () => {
    var config = {
      url: `/product-category/all`,
      method: "GET",
    };
    instance(config)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (response.data.status === true) {
            var categoryData = [];
            if (response?.data?.data && response?.data?.data?.length > 0) {
              response?.data?.data?.forEach((element) => {
                categoryData.push({
                  value: element._id,
                  label: element.name,
                });
              });
              setCategories(categoryData ?? []);
            }
          } else {
            // message.error(response.data.message);
          }
        } else {
          // message.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  useEffect(() => {
    fetchProductCategory();
  }, []);
  // TEXT EDITOR

  // INSERT SERVICES
  const onFinish = (values) => {
    setBtnLoading(true);
    let formData = new FormData();
    formData.append("product_name", values?.name);
    formData.append("category", values?.category);
    formData.append("introduction", values?.introduction);
    formData.append("description", values?.description);
    formData.append("meta_title", values.meta_title);
    formData.append("head_title", values.head_title);
    formData.append("meta_description", values.meta_description);
    formData.append("meta_keyword", values.meta_keyword);
    formData.append("slug", values.slug);
    if (values?.images?.fileList && values?.images?.fileList?.length > 0) {
      values?.images?.fileList?.forEach((element) => {
        formData.append("images", element?.originFileObj);
      });

      var config = {
        url: `/product/create`,
        method: "POST",
        data: formData,
        headers : {
          "Content-Type" : "multipart/form-data"
        }
      };

      instance(config)
        .then((response) => {
          if (response?.status >= 200 && response?.status < 300) {
            if (response.data.status) {
              message.success({
                content: response.data.message,
                key: "loading",
              });
              form.resetFields();
              setBtnLoading(false);
              // alert(res.data.message)
            } else {
              message.error({ content: response.data.message, key: "loading" });
              setBtnLoading(false);
            }
          } else {
            message.error("Something went wrong! try again later");
            setBtnLoading(false);
          }
        })
        .catch((error) => {
          // Server can't be reached!
          if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message);
            setBtnLoading(false);
          } else {
            message.error(error?.message);
            setBtnLoading(false);
          }
        });
    } else {
      message.error("Upload product images before submit!");
      setBtnLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-body">
              <p className="fw-500 mb-0 fs-5">
                Product
                <span className="primary-font ms-2">(On website)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="card border-0">
            <div className="card-header border-0">
              <p className="mb-0 fw-500 fs-6 secondary-font">Add New Product</p>
            </div>
            <div className="card-body">
              <Form
                name="product_form"
                className="login-form"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="name"
                      label="Product name"
                      rules={[
                        {
                          required: true,
                          message: "Please input product name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Product Name"
                        onChange={(e)=>createSlug(e)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="category"
                      label="Product category"
                      rules={[
                        {
                          required: true,
                          message: "Please select product category!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select product category"
                        options={cateagories}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="introduction"
                      label="Product introduction"
                      rules={[
                        {
                          required: true,
                          message: "Please input product introduction!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder="Enter Product introduction"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="images"
                      label="Product images"
                      rules={[
                        {
                          required: true,
                          message: "Please input product images!",
                        },
                      ]}
                    >
                      <Upload
                        listType="picture-card"
                        accept="image/*"
                        multiple={true}
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_title"
                      label="Meta title"
                      rules={[
                        { required: true, message: "Please input meta title!" },
                      ]}
                    >
                      <Input placeholder="Enter Meta Title" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_keyword"
                      label="Meta keyword"
                      rules={[
                        {
                          required: true,
                          message: "Please input meta keyword!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter meta keyword" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="meta_description"
                      label="Meta description"
                      rules={[
                        { required: true, message: "Please input your name!" },
                      ]}
                    >
                      <TextArea placeholder="Enter Meta Description" rows={5} />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="head_title"
                      label="Head title"
                      rules={[
                        { required: true, message: "Please input head title!" },
                      ]}
                    >
                      <Input placeholder="Enter Head Title" />
                    </Form.Item>
                    <Form.Item
                      name="slug"
                      label="Product slug"
                      rules={[
                        {
                          required: true,
                          pattern: /[a-zA-Z0-9\-\_]$/,
                          message:
                            "Only characters ,number and underscore is allowed in slug",
                          // message: "Please input product slug!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Product Slug" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    name="description"
                    label="Product description"
                    rules={[
                      {
                        required: true,
                        message: "Please input product description!",
                      },
                    ]}
                  >
                    {/* <ReactQuill theme="snow" modules={modules} /> */}

                    <SunEditor
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                          [ "codeView"],
                        ],height:"150px"
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-12 mt-2">
                  <Form.Item className="text-end">
                    <Button
                      htmlType="submit"
                      className="form-btn-style"
                      disabled={btnLoading ? true : false}
                    >
                      {btnLoading ? (
                        <span className="me-2">
                          <ButtonLoader />
                        </span>
                      ) : (
                        ""
                      )}
                      Add Product
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
